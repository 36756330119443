import { ResponseStatus } from "types";
import { OrderStatus } from "types/orders";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  getAvatarURL,
  getCurrentUserOrders,
  getCurrentUserProfile,
  removeAvatarURL,
  setAvatar,
  setCurrentUserProfile,
} from "services/ProfileService";
import GSChatService from "services/GSChatService";
import { CurrentUser } from "models";

export interface ProfileState {
  currentUserStatus: ResponseStatus | null;
  savingUserDataStatus: ResponseStatus | null;
  currentUser: CurrentUser | null;
  activeOrdersCount: number;
}

const initialState: ProfileState = {
  currentUserStatus: null,
  savingUserDataStatus: null,
  currentUser: null,
  activeOrdersCount: 0,
};

export const getCurrentUserAsync = createAsyncThunk("user/getCurrentUserAsync", async () => {
  const profile = await getCurrentUserProfile();
  return new CurrentUser(profile);
});

export const getCurrentUserActiveOrdersAsync = createAsyncThunk("user/getCurrentUserActiveOrdersAsync", async () => {
  return await getCurrentUserOrders([
    OrderStatus.CREATED,
    OrderStatus.PAID_OUT,
    OrderStatus.CHANGED,
    OrderStatus.CONFIRMED
  ]);
});

export const setUserDataAsync = createAsyncThunk("auth/setProfileDataAsync", async (formData: any, { rejectWithValue }) => {
  if (formData.username) {
    await GSChatService.updateUser({ name: formData.username });
  }

  try {
    const profile = await setCurrentUserProfile(formData);

    return new CurrentUser(profile);
  } catch (error) {
    // Throw error without createAsyncThunk-transformation
    throw rejectWithValue(error);
  }
});

export const setUserAvatarAsync = createAsyncThunk("auth/setUserAvatarAsync", async (avatar: string) => {
  if (avatar !== undefined) {
    if (!!avatar) {
      const avatarResp = await getAvatarURL();
      const file = await fetch(avatar).then((res) => res.blob());
      await setAvatar(avatarResp.avatar_upload_url, file);
      await GSChatService.updateUser({ image: avatarResp.avatar_url });
    } else {
      await removeAvatarURL();
      await GSChatService.updateUser({ image: null });
    }
  }
  const profile = await getCurrentUserProfile();
  return new CurrentUser(profile);
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    unsetSavingUserDataStatus: (state) => {
      state.savingUserDataStatus = null;
    },
    clearCurrentUser: (state) => {
      state.savingUserDataStatus = null;
      state.currentUserStatus = null;
      state.currentUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserAsync.pending, (state) => {
        state.currentUserStatus = ResponseStatus.LOADING;
      })
      .addCase(getCurrentUserAsync.fulfilled, (state, action) => {
        state.currentUserStatus = ResponseStatus.IDLE;
        state.currentUser = action.payload;
      })
      .addCase(getCurrentUserAsync.rejected, (state) => {
        state.currentUserStatus = ResponseStatus.FAILED;
      })
      .addCase(getCurrentUserActiveOrdersAsync.fulfilled, (state, action) => {
        state.currentUserStatus = ResponseStatus.IDLE;
        state.activeOrdersCount = action.payload.length;
      })
      .addCase(setUserAvatarAsync.pending, () => {})
      .addCase(setUserAvatarAsync.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      })
      .addCase(setUserAvatarAsync.rejected, () => {})
      .addCase(setUserDataAsync.pending, (state) => {
        state.savingUserDataStatus = ResponseStatus.LOADING;
      })
      .addCase(setUserDataAsync.fulfilled, (state, action) => {
        state.savingUserDataStatus = ResponseStatus.IDLE;
        state.currentUser = action.payload;
      })
      .addCase(setUserDataAsync.rejected, (state) => {
        state.savingUserDataStatus = ResponseStatus.FAILED;
      });
  },
});

export const userSelectors = {
  currentUserStatus: (state: RootState) => state.user.currentUserStatus,
  currentUser: (state: RootState) => state.user.currentUser,
  currentUsername: (state: RootState) => state.user.currentUser?.username,
  firstName: (state: RootState) => state.user.currentUser?.firstName,
  lastName: (state: RootState) => state.user.currentUser?.lastName,
  avatarURL: (state: RootState) => state.user.currentUser?.avatarURL,
  savingUserDataStatus: (state: RootState) => state.user?.savingUserDataStatus,
  activeOrdersCount: (state: RootState) => state.user?.activeOrdersCount,
};

export const { clearCurrentUser, unsetSavingUserDataStatus } = userSlice.actions;
export default userSlice.reducer;
